import './App.css';
import {  Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ReactPlayer from 'react-player';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from "react-google-recaptcha";
import Carousel from 'react-bootstrap/Carousel';
import React, { useEffect, useState } from "react";
import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

function App() {
    const [user, setUser] = useState([]);	
	
const recaptchaRef = React.createRef();
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
  const fetchData = () => {
    return fetch("./review_fetch.php")
          .then((response) => response.json())
          .then((data) => setUser(data));
	/*	return fetch("./reviews.json")
          .then((response) => response.json())
          .then((data) => setUser(data)); */
  }

  useEffect(() => {
    fetchData();
  },[])

var success_msg = '';
  const handleSubmit = (event) => {	 
    event.preventDefault();
	const recaptchaValue = recaptchaRef.current.getValue();
	event.target.name.classList.remove("error_msg");
	event.target.email.classList.remove("error_msg");
	event.target.message.classList.remove("error_msg");
	document.getElementById("success_msg").classList.remove("success_msg")
	if(event.target.name.value == ''){
	   event.target.name.classList.add("error_msg");
	 
	}
	if(event.target.email.value == ''){
	   event.target.email.classList.add("error_msg");
	   
	}
	if(event.target.message.value == ''){
	   event.target.message.classList.add("error_msg");
	  
	}
	if((recaptchaValue != '') && (event.target.name.value != '') && (event.target.email.value != '') && (event.target.message.value != '')) {
fetch('./mail_send.php', {
  method: 'POST',
  body: JSON.stringify({
    name : event.target.name.value,
	email : event.target.email.value,
	message : event.target.message.value
  }),
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
})
   .then((response) => response.json())
   .then((data) => {
       document.getElementById("success_msg").classList.add("success_msg");
      // Handle data
   })
   .catch((err) => {
      console.log(err.message);
	 
   });
    console.log();
  
  }};
   return (
    <div>
 <Navbar bg="light" expand="lg"  sticky="top">
      <Container>
        <Navbar.Brand href="#home">
		<LazyLoadImage className="logo_cl" width="300" height="69" src ="imgs/MADLIB-SOLUTIONS-LOGO.png"  alt ="MADLIB-SOLUTIONS-LOGO"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="flex-row-reverse">
          <Nav className="mr-auto flex-row-reverse">
            <Nav.Link  onClick={() =>  window.scrollTo({     left: 0,  top: document.getElementById("about").offsetTop-70,
            behavior: "smooth",})}>About</Nav.Link>
            <Nav.Link   onClick={() =>  window.scrollTo({     left: 0,  top: document.getElementById("services").offsetTop-70,
            behavior: "smooth",})}>Services</Nav.Link>
			<Nav.Link   onClick={() =>  window.scrollTo({     left: 0,  top: document.getElementById("projects").offsetTop-70,
            behavior: "smooth",})}>Projects</Nav.Link>
			<Nav.Link   onClick={() =>  window.scrollTo({     left: 0,  top: document.getElementById("testimonials").offsetTop-70,
            behavior: "smooth",})}>Testimonials</Nav.Link>
			<Nav.Link   onClick={() =>  window.scrollTo({     left: 0,  top: document.getElementById("contact").offsetTop-70,
            behavior: "smooth",})}>Contact</Nav.Link>
		  </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
	<div className="banner_img">
		<ReactPlayer id="home" loop={true} playing={true} muted={true} className="vdo" url="imgs/madlib-solutions.mp4" controls={false} />
		<div className="banner_loop">
			<div className="learn">
				Leading IT Company For<br/>Software Solution<br/>
				<a href="#" className="learnmore" onClick={() =>  window.scrollTo({     left: 0,  top: document.getElementById("services").offsetTop-70,
            behavior: "smooth",})} >Learn More</a>
			</div>
		</div>
	</div>
  <Container>
   <Row className="content" id="about">
    <Col xs={12} md={6} className="pad_gap">
        <LazyLoadImage src="imgs/Company-Management.jpg" width="550" height="263" alt="About Us"  className="img-responsive" />
    </Col>
	<Col xs={12} md={6} className="pad_gap">
        <h2>ABOUT US</h2>
		<br/>
		<p>Madlib is a professional IT company specializing in web development, E-Commerce, mobile application, and internet marketing services. With a strong focus on software solutions, we cater to the unique needs of domestic and international clients. Our founders, with over 12 years of industry experience, have played pivotal roles in delivering value to clients worldwide.</p>
        <br/>
		<h3>Why Choose Us?</h3>
		<Row className="gap-left abt_tick">
		    <Col xs={12} md={12} lg={6}>
			  <LazyLoadImage src="imgs/tick.png" width="26" height="14" alt = "tick"/><label>High quality work</label>
			</Col>
			<Col xs={12} md={12} lg={6}>
			  <LazyLoadImage src="imgs/tick.png" width="26" height="14" alt = "tick"/><label>Implementing meaningful ideas</label>
			</Col>
		</Row>
		<Row className="gap-left">
		    <Col xs={12} md={12} lg={6}>
			  <LazyLoadImage src="imgs/tick.png" width="26" height="14" alt = "tick"/><label>Optimize the cost of the services</label>
			</Col>
			<Col xs={12} md={12} lg={6}>
			  <LazyLoadImage src="imgs/tick.png" width="26" height="14" alt = "tick"/><label>Availability of resources</label>
			</Col>
		</Row>
	</Col>
  </Row>
</Container>
<Container fluid className="no-padding back_blue"  id="services">
 <Container>
		  <Row className="justify-content-md-center">
			  <Col className="pad_gap ">
				<h2>Our Services</h2>
				<p>We understand your need and intention because computers follow your orders, not your intentions. So we are present here to turn your ideas
		and needs into design and implement them in our code. Let us know if we can help?</p>
			   
			</Col>
		  </Row>
		  <Row>
			 <Col s={6} xs={12} md={3} className="pad_gap text-center">
				 <LazyLoadImage width="120" height="120" src = "imgs/Web-Development.png" alt="service 1"/>
				 <h3> Web Development </h3>
				 <p>Microservice Architecture are one of our confident specialty. Our team has a great hands on experience on almost all the languages available as of now. Our diversified team is great on java, .net, php, python  etc.
		</p>
			</Col>
			<Col s={6} xs={12} md={3} className="pad_gap text-center">
				 <LazyLoadImage width="120" height="120" src = "imgs/Mobile-Applications.png"  alt="service 1"/>
				 <h3>Mobile Applications</h3>
				 <p>Now every person carries Smartphone for faster and easy access. If your business is looking for a native or hybrid iOS, Android app and want a cross-platform responsive web app along with a secure backend? We are here to help.</p>
			</Col>
			<Col s={6} xs={12} md={3} className="pad_gap text-center">
				 <LazyLoadImage width="120" height="120" src = "imgs/E-Commerce.png"  alt="service 1"/>
				 <h3>E-Commerce</h3>
				 <p>As the ecommerce industry is evolving we are also evolving along with it at the same pace. Our go getter team has an expertise on Magento,  Node JS etc technologies. Major new businesses from AU, EU & US market has taken our expertise and are running successfully.
</p>
			</Col>
			<Col s={6} xs={12} md={3} className="pad_gap text-center">
				 <LazyLoadImage width="120" height="120" src = "imgs/Internet-Marketing.png"  alt="service 1"/>
				 <h3>Internet Marketing</h3>
				 <p>
		Internet marketing also known as web advertising Which is the part of advertising and marketing globally to convey promotional marketing messages to customers.</p>
			</Col>
		  </Row>
	</Container> 
</Container> 
<Container id="projects">  
   <Row className="content">
    <Col className="pad_gap" >
        <h2 className="projects" >Projects</h2>
		<br/>		
		<Row className="gap-left">
		  <CarouselMulti responsive={responsive}>	
		    <div>	
			  <LazyLoadImage src="imgs/yummaboo.png" width="780" height="488" className="img-responsive pro_img" alt = "yummaboo"/>
			  <div className="hoverCl"><a target="_blank" href="https://yummaboo.com" title="Yummaboo">Yummaboo</a></div>
			</div>
			 <div>	
			  <LazyLoadImage src="imgs/ce4rt.png" width="780" height="488" className="img-responsive pro_img" alt = "ce4rt"/>
			  <div className="hoverCl"><a target="_blank" href="https://ce4rt.com" title="CE4RT">CE4RT</a></div>
			</div>	
			<div>	
			  <LazyLoadImage src="imgs/bizzle.png" width="780" height="488" className="img-responsive pro_img" alt = "bizzle"/>
			  <div className="hoverCl"><a target="_blank" href=" https://bizzlebook.com" title="Bizzle">Bizzle</a></div>
			</div>			
			 <div>	
			  <LazyLoadImage src="imgs/swe.png" width="780" height="488" className="img-responsive pro_img" alt = "swe"/>
			  <div className="hoverCl"><a target="_blank" href="https://swe.org" title="Society of Women Engineers">Society of Women Engineers</a></div>
			</div>
			 <div>	
			  <LazyLoadImage src="imgs/stjoseph.png" width="780" height="488" className="img-responsive pro_img" alt = "stjoseph"/>
			  <div className="hoverCl"><a target="_blank" href="https://stjosephshealth.org" title="St. Joseph's Health">St. Joseph's Health</a></div>
			</div>
			 <div>	
			  <LazyLoadImage src="imgs/quilt.png" width="780" height="488" className="img-responsive pro_img" alt = "quilt"/>
			  <div className="hoverCl"><a target="_blank" href="https://thequiltshow.com" title="The Quilt Show">The Quilt Show</a></div>
			</div>
			
			 </CarouselMulti>
		</Row>
		<h2  id="testimonials" className="projects">Client's Testimonials</h2>
		<br/>		
		<Row className="gap-left">
		    <Col>
			  <Carousel variant="dark">				  					  
					{user && user.length > 0 && user.map((userObj, index) => ( <Carousel.Item>					
					<Carousel.Caption>         
					 <LazyLoadImage width="400" height="180"
					  className="d-block w-20"
					  src={userObj.profile_pic}
					  alt="profile_pic"
					/><div className="flt_nme"> <h3>{userObj.name}</h3>
					  <p className="nulla">{userObj.comp}</p></div>
					<div className="revii">
					   {userObj.rev_star.length > 0 && userObj.rev_star.map((rev_star, ind) =>
					   ( 
					   rev_star===1? <LazyLoadImage width="30" height="30" className="d-block w-20"	  src="imgs/star-color.png" alt="Review Star"/>:<LazyLoadImage width="30" height="30" className="d-block w-20"	  src="imgs/star-blank.png" alt="Review Star"/>
					  
					    ))}
						<label>{userObj.rev}/5  | {userObj.datetime}</label>
					</div>
					<div className="reviis">
						<p>{userObj.summ}</p>
					</div>
					</Carousel.Caption>
				  </Carousel.Item>
					 ))}
									  
				</Carousel>
			</Col>			
		</Row>
	</Col>
  </Row>
</Container> 
<Container fluid className="back_blue1" id="contact" >
	<Container>
		<Row>
		  <Col>
			<h2>Get In Touch</h2>
		   </Col>
	   </Row>
	   <Row>
		  <Col s={12} xs={12} md={8}>  		
			<p>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
			 <form onSubmit={handleSubmit} className="form get_in">
			 <div id="success_msg">Message has been sent.</div>
				<Row className="form_seperator">
					  <Col s={12} xs={12} md={6}>				 
						<Form.Control name="name" type="text"   placeholder="Name"  className="form-control" id="name-form" />
					  </Col>
					  <Col s={12} xs={12} md={6}>				 
						<Form.Control name="email" type="email" placeholder="E-mail" className="form-control" id="email-form" />
					  </Col>
				 </Row>
				 <Row className="form_seperator">
					 <Col>				 
						<textarea name="message" type="textarea" rows="{25}" placeholder="Message" className="form-control" id="message-form" ></textarea>
					  </Col>			      
				 </Row>
				  <Row className="form_seperator">
					 <Col>	
						   <ReCAPTCHA   ref={recaptchaRef}
							sitekey="6Lfq4VomAAAAAGz6ldwBAKt3Fx7s67AgOv_Pfdic" />
					</Col>			      
				 </Row>
				 <Row className="form_seperator">
					 <Col>				 
						<button type="submit"  className="btn btn-default" id="submit-form">Send Message</button>
					  </Col>			      
				 </Row>
			 </form>
		  </Col>
		  <Col className="contact_info" s={12} xs={12} md={4}>
			<h3>Contact Info</h3>
			
			<div className="rw">
			  <label className="lft_lbl"><LazyLoadImage width="23" height="24" src="imgs/mail.png" className="img-responsive" alt = "call"/></label>
			  <div className="back_wht1 wd_200" >info@madlibsolutions.com</div>
			</div>
			<div className="rw">
			  <h4 >Overseas Address</h4>
			   <label className="lft_lbl"><LazyLoadImage width="23" height="24" src="imgs/map.png" className="img-responsive" alt = "call"/></label>
			  <div className="back_wht1 wd_300"  >75 Windella Crescent, Glen Waverley <br/>
Victoria, Melbourne, Australia 3150</div>
			</div>
		</Col>
	  </Row>
	  </Container>
</Container>
	  <Container id="footer">  
		   <Row className="content">
			<Col>
			  © 2023 MadLib Solutions PVT. LTD. 
			</Col>
		  </Row>
		</Container> 

</div>
  );
}

export default App;
